.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.swiper {
  width: 90% !important;
  padding: 0 64px !important;
  padding-top: 40px !important;
}

.swiper > div {
  background-image: url(../../../assets/images/vectorRight.png);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 34px;
  top: 200px;
  left: auto;
  width: 30px;
  height: 40px;
  cursor: pointer;
  z-index: 99;
  transition: all 0.3s ease-out 0.1s;
}

.swiper > div:nth-child(2) {
  background-image: url(../../../assets/images/vectorLeft.png);
  left: 34px;
  right: auto;
}

.swiper div:after {
  font-size: 16px;
}

.title {
  padding: 100px 0;
}

.noNav,
.monthView {
  border: none !important;
}

.noNav {
  width: 250px !important;
}

.noNav > div:nth-child(1) {
  margin: 0 !important;
}

.noNav > div:nth-child(1) button:nth-child(-n + 2),
.noNav > div:nth-child(1) button:nth-child(n + 4) {
  display: none;
}

.noNav > div:nth-child(1) button:nth-child(3) {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  text-align: left;
  padding: 0 !important;
}

.noNav
  > div:nth-child(2)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  abbr {
  text-decoration: none !important;
  text-transform: capitalize;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--black);
}

.noNavTile,
.noNavSelectedTile,
.noNavHolidayTile,
.noNavJobTile,
.noNavNotAllowTile,
.weekend {
  border: 1px solid var(--stroke) !important;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
  width: 36px;
  height: 36px;
  overflow: unset !important;
}

.weekend {
  background: #f5f5f5 !important;
}

.noNavSelectedTile {
  background: transparent !important;
  color: var(--black) !important;
  border: 2px solid var(--secondary) !important;
}

.noNavSelectedTile:hover {
  background: transparent !important;
}

.monthView {
  width: 1000px !important;
}

.monthView > div:nth-child(1) button:nth-child(-n + 1),
.monthView > div:nth-child(1) button:nth-child(n + 5) {
  display: none;
}

.monthView > div:nth-child(1) button:nth-child(2),
.monthView > div:nth-child(1) button:nth-child(3),
.monthView > div:nth-child(1) button:nth-child(4) {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: var(--black);
}

.monthView > div:nth-child(1) button:nth-child(3) {
  flex-grow: 0 !important;
}

.monthView
  > div:nth-child(2)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1) {
  text-align: left;
}

.monthView
  > div:nth-child(2)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  > div {
  flex: 0 0 124px !important;
  margin-right: 18px !important;
}

.monthView
  > div:nth-child(2)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(7) {
  margin-right: 0 !important;
}

.monthView
  > div:nth-child(2)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(1)
  abbr {
  text-decoration: none !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--black);
}

.monthViewTile,
.monthViewSelectedTile,
.monthViewWeekend {
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 120px;
  max-width: 124px !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  border: 4px solid var(--white) !important;
  text-align: left !important;
  margin-right: 18px !important;
  margin-bottom: 20px !important;
  padding: 0 !important;
}

.monthViewTile:nth-child(7n),
.monthViewSelectedTile:nth-child(7n),
.monthViewWeekend:nth-child(7n) {
  margin-right: 0 !important;
}

.monthViewWeekend {
  color: var(--black) !important;
  background: #f5f5f5 !important;
}

.monthViewWeekend:nth-child(2) {
  margin-right: 0 !important;
}

.monthViewSelectedTile {
  color: var(--black) !important;
  background: none !important;
  border: 4px solid var(--secondary) !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.border {
  width: 100%;
  height: 4px;
  background: var(--black);
}

.innerCell {
  display: flex;
  align-items: center;
  margin-left: 12px;
  line-height: 2;
  margin-right: 54px;
}

.innerCellText {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 10px;
  line-height: 1.6;
  color: var(--black);
}

.icon {
  margin-right: 10px;
}

.tooltipWeekday {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 16px;
}

.tooltipDay {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.tooltipList {
  margin-left: 12px;
  margin-bottom: 16px;
}

.tooltipListItem {
  display: flex;
  align-items: center;
}

.tooltipColor {
  width: 6px;
  height: 6px;
  margin-right: 8px;
  border-radius: 50%;
}

.tooltipTitle {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.red {
  background: var(--error);
}

.green {
  background: var(--green);
}

.blue {
  background: var(--primary);
}

.purple {
  background: var(--purple);
}

.orange {
  background: var(--accent);
}

.blueText {
  color: var(--primary);
}

.orangeText {
  color: var(--accent);
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.bigDot {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.line {
  width: 16px;
  height: 2px;
  border-radius: 1px;
}

.innerFlex {
  display: flex;
  align-items: center;
}

.outTT {
  position: relative;
  width: 100%;
  height: 100%;
}

.inTT {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.tooltip {
  display: none;
  width: 160px;
  position: absolute;
  bottom: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  color: var(--black);
  background-color: var(--white);
  z-index: 999;
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.TTvisible {
  display: block;
}

.TTarrow {
  position: relative;
  bottom: -6px;
  width: 12px;
  height: 12px;
  background: var(--white);
  margin: 0 auto;
  transform: rotate(45deg);
}
